import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import css from './ProductsListRow.module.css';
import { NamedLink, FormattedAttributes, NamedLinkButton } from '../../components';
import { createSlug } from '../../util/urlHelpers';
import { formatMoney, Money } from '../../util/currency';

function ProductslistRow({
  productId,
  productTitle,
  productPreviewImage,
  variantType,
  allAttributes,
  quantity,
  price,
  isDigital,
  isLink,
  isPurchased,
  isBuyer,
  digitalGoodUrl,
  intl,
  transactionId,
  productType,
  campaignTitle,
}) {
  const id = productId;
  const slug = createSlug(productTitle);
  console.log(digitalGoodUrl);
  return (
    <Fragment key={productId}>
      <div className={css.productPreviewContainer}>
        <div className={css.productPreviewRow}>
          <div className={css.quantityDotContainer}>
          <div className={css.quantityDot}>{quantity}</div>
          <img className={css.productPreviewImage} src={productPreviewImage}></img>
          </div>
          <div className={css.productRowName}>
            <div>
              <NamedLink className={css.noDecorLink} name="ListingPage" params={{ id, slug }}>
                {productTitle}
              </NamedLink>
            </div>
            <div>
              <FormattedAttributes variantType={variantType} allAttributes={allAttributes} />
            </div>
            {/* <div>
              <b className={css.quantityLabel}>Quantity: </b>
              <span className={css.quantityValue}>{quantity}</span>
            </div> */}
          </div>
          <div className={css.spacer}></div>
          <div className={css.price}>
            {price?._sdkType == 'Money' ? formatMoney(intl, price) : `${price}$`}
          </div>
        </div>
        {isPurchased && isDigital && isLink && isBuyer && (
          <div className={css.openUrlButton}>
            <NamedLinkButton
              external
              href={
                !!campaignTitle
                  ? `/digitalgoods/${campaignTitle}?id=${id}&transactionId=${transactionId}`
                  : digitalGoodUrl
              }
            >
              Open URL
            </NamedLinkButton>
          </div>
        )}
      </div>
    </Fragment>
  );
}

ProductslistRow.propTypes = {
  product: PropTypes.object,
};

export default ProductslistRow;
