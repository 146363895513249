import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './Topbar.module.css';

const BagIcon = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.rootSearchIcon, className);

  return (
    <svg
      className={classes}
      width="35"
      height="34"
      viewBox="0 0 35 34"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9476 14.831H20.7391L18.5053 10.1005C18.296 9.65731 18.4856 9.12843 18.9288 8.91916C19.3719 8.70989 19.9008 8.8995 20.1101 9.34265L22.7018 14.831H26.0082C26.556 14.831 27 15.275 27 15.8227C27 16.3705 26.556 16.8145 26.0082 16.8145H22.734C22.6027 16.5661 22.3418 16.3969 22.0413 16.3969C21.7409 16.3969 21.4799 16.5661 21.3486 16.8145H13.6515C13.5202 16.5661 13.2593 16.3968 12.9588 16.3968C12.6583 16.3968 12.3973 16.5661 12.2661 16.8145H8.99176C8.44403 16.8145 8 16.3705 8 15.8227C8 15.275 8.44403 14.831 8.99176 14.831H11.9849L14.5767 9.34265C14.7859 8.8995 15.3148 8.70989 15.758 8.91916C16.2011 9.12843 16.3907 9.65731 16.1814 10.1005L13.9476 14.831ZM12.2661 16.8145H9.1484L10.8409 23.5846C11.0733 24.5141 11.9084 25.1661 12.8665 25.1661H22.1956C23.1491 25.1661 23.9815 24.5202 24.2182 23.5965L25.9561 16.8145H22.734C22.7916 16.9236 22.8243 17.0479 22.8243 17.1799C22.8243 17.6123 22.4737 17.9628 22.0413 17.9628C21.6089 17.9628 21.2583 17.6123 21.2583 17.1799C21.2583 17.0479 21.291 16.9236 21.3486 16.8145H13.6515C13.7091 16.9235 13.7417 17.0478 13.7417 17.1798C13.7417 17.6122 13.3912 17.9627 12.9588 17.9627C12.5263 17.9627 12.1758 17.6122 12.1758 17.1798C12.1758 17.0478 12.2084 16.9235 12.2661 16.8145Z"
        fill="black"
      />
    </svg>
  );
};

const { string } = PropTypes;

BagIcon.defaultProps = {
  className: null,
  rootClassName: null,
};

BagIcon.propTypes = {
  className: string,
  rootClassName: string,
};

export default BagIcon;
