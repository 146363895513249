import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routing/routeConfiguration';
import { LinkTabNavHorizontal } from '..';
import homeIcon from '../../assets/home_icon.svg';
import chartIcon from '../../assets/chart_icon.svg';

import css from './ShopNav.module.css';

const ShopNav = props => {
  const { className, rootClassName, selectedPageName, notificationCount } = props;
  const classes = classNames(rootClassName || css.root, className);

  const tabs = [
    {
      text: <FormattedMessage id="SellerDashboardPage.title" />,
      icon: <img style={{paddingRight:'12px', color: selectedPageName === 'SellerDashboardPage' ? 'blue': 'black'}} src={homeIcon}></img>,
      selected: selectedPageName === 'SellerDashboardPage',
      linkProps: {
        name: 'SellerDashboardPage',
      },
    },
    {
      text: <FormattedMessage id="ManageListingsPage.yourListings" />,
      icon: <img style={{paddingRight:'12px', color: selectedPageName === 'ManageListingsPage' ? 'blue': 'black'}} src={chartIcon}></img>,
      selected: selectedPageName === 'ManageListingsPage',
      linkProps: {
        name: 'ManageListingsPage',
      },
    },
    {
      text: <FormattedMessage id="InboxPageSeller.title" />,
      icon: <img style={{paddingRight:'12px', color: selectedPageName === 'InboxPageSeller' ? 'blue': 'black'}} src={chartIcon}></img>,

      selected: selectedPageName === 'InboxPageSeller',
      hasNotification: notificationCount > 0,
      linkProps: {
        name: 'InboxPageSeller',
      },
    },
    {
      text: <FormattedMessage id="ManageShipingPage.yourShipping" />,
      icon: <img style={{paddingRight:'12px', color: selectedPageName === 'ManageShippingPage' ? 'blue': 'black'}} src={chartIcon}></img>,

      selected: selectedPageName === 'ManageShippingPage',
      linkProps: {
        name: 'ManageShippingPage',
      },
    },
    {
      text: <FormattedMessage id="ShopDetailsPage.title" />,
      icon: <img style={{paddingRight:'12px', color: selectedPageName === 'ShopDetailsPage' ? 'blue': 'black'}} src={chartIcon}></img>,

      selected: selectedPageName === 'ShopDetailsPage',
      linkProps: {
        name: 'ShopDetailsPage',
      },
    },
    // {
    //   text: <FormattedMessage id="PayPalMerchantPage.title" />,
    //   selected: selectedPageName === 'PayPalMerchantPage',
    //   linkProps: {
    //     name: 'PayPalMerchantPage',
    //   },
    // },
  ];

  return (
    <LinkTabNavHorizontal className={classes} tabRootClassName={css.tab} tabs={tabs} skin="light" />
  );
};

ShopNav.defaultProps = {
  className: null,
  rootClassName: null,
};

const { string } = PropTypes;

ShopNav.propTypes = {
  className: string,
  rootClassName: string,
  selectedPageName: string.isRequired,
};

const mapStateToProps = state => {
  // ShopNav needs user info.
  const { currentUser, currentUserNotificationCount: notificationCount } = state.user;
  return {
    currentUser,
    notificationCount,
  };
};

const ShopNavContainer = compose(withRouter, connect(mapStateToProps))(ShopNav);

export default ShopNavContainer;
