import config from '../../config';
import lodash from 'lodash';
import moment from 'moment';
import { transactionLineItems } from '../../util/api';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
// ================ Action types ================ //

export const ADD_TO_CART = 'app/shoppingCart/ADD_TO_CART';
export const GET_CART_ITEMS = 'app/shoppingCart/GET_CART_ITEMS';
export const LOADING_DATA = 'app/shoppingCart/LOADING_DATA';
export const GET_CART_ITEMS_ERROR = 'app/shoppingCart/GET_CART_ITEMS_ERROR';
export const DELETE_CART_ITEM = 'app/shoppingCart/DELETE_CART_ITEM';
export const DELETE_CART_ITEM_ERROR = 'app/shoppingCart/DELETE_CART_ITEM_ERROR';
export const OLD_CART = 'app/shoppingCart/OLD_CART';
export const EDIT_AMOUNT = 'app/shoppingCart/EDIT_AMOUNT';
export const GET_CART_AMOUNT_AMOUNT_OF_ITEMS = 'app/shoppingCart/GET_CART_AMOUNT_OF_ITEMS';
export const CALCULATE_SHIPPING = 'app/shoppingCart/CALCULATE_SHIPPING';
export const GET_SELLER = 'app/shoppingCart/GET_SELLER';
export const EMPTY_CART = 'app/shoppingCart/EMPTY_CART';
export const REMOVE_PURCHASE_FROM_CART_AFTER_PURCHASE =
  'app/shoppingCart/REMOVE_PURCHASE_FROM_CART_AFTER_PURCHASE';
export const REMOVE_PURCHASE_FROM_CART_AFTER_PURCHASE_ERROR =
  'app/shoppingCart/REMOVE_PURCHASE_FROM_CART_AFTER_PURCHASE_ERROR';
export const EMPTY_CART_ERROR = 'app/shoppingCart/EMPTY_CART_ERROR';

// ================ Reducer ================ //

const initialState = {
  // Database of all the fetched entities.
  loading: false,
  error: null,
  cartItems: [],
};

export default function shopingCartReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_CART_ITEMS:
      return { ...state, cartItems: payload, loading: false };
    case GET_CART_ITEMS_ERROR:
      return { ...state, loading: false, error: payload };
    case DELETE_CART_ITEM:
      return { ...state, cartItems: { data: payload.cartItems }, success: true };
    case LOADING_DATA:
      return { ...state, loading: true };
    case ADD_TO_CART:
      return { ...state, itemAdded: payload };
    case OLD_CART:
      return { ...state, cartIsDestroyed: true, loading: false };
    case EDIT_AMOUNT:
      return { ...state, cartItems: payload, amountChanged: true };
    case GET_CART_AMOUNT_AMOUNT_OF_ITEMS:
      return {
        ...state,
        cartSummary: payload,
      };
    case CALCULATE_SHIPPING:
      return {
        ...state,
        [`shippingCost-${payload.sellerId}`]: payload.isForbidden
          ? 'forbidden'
          : payload.shippingCost,
      };
    case GET_SELLER:
      return { ...state, seller: payload };
    case REMOVE_PURCHASE_FROM_CART_AFTER_PURCHASE:
      return { ...state, clearPurchaseFromShoppingCart: 'succesfull' };
    case REMOVE_PURCHASE_FROM_CART_AFTER_PURCHASE:
      console.error(payload);
      return {
        ...state,
        clearPurchaseFromCartError: payload,
        clearPurchaseFromShoppingCart: 'failed',
      };
    case EMPTY_CART:
      return { ...state };
    case EMPTY_CART_ERROR:
      return { ...state, emptyCartError: payload };
    default:
      return state;
  }
}

// ================ Helpers ================= //

const bundleByAuthor = cart => {
  const productsBySeller = lodash.groupBy(cart, product => product.user.id.uuid);

  const shoppingCartLocal = JSON.parse(localStorage.getItem('shoppingCart'));
  let productsObject = {};
  if (shoppingCartLocal?.products) {
    shoppingCartLocal.products.forEach(item => (productsObject[item.listingId.uuid] = item));
  }

  let temp = Object.keys(productsBySeller).map(key => {

    return {
      sellerName: productsBySeller[key][0].user.attributes.profile.publicData.shopName ? productsBySeller[key][0].user.attributes.profile.publicData.shopName : productsBySeller[key][0].user.attributes.profile.displayName,
      sellerImage: productsBySeller[key][0].user.attributes.profile.publicData.shopName ? productsBySeller[key][0].user.attributes.profile.publicData.shopName : productsBySeller[key][0].user.attributes.profile.displayName,

      sellerId: key,
      sellerShippingData: {
        rangesType: productsBySeller[key][0].user.attributes.profile.publicData.rangesType,
        shippingCountries:
          productsBySeller[key][0].user.attributes.profile.publicData.shippingCountries,
        shippingRanges: productsBySeller[key][0].user.attributes.profile.publicData.shippingRanges,
      },
      products: productsBySeller[key],
    };
  });

  const tempWithPrice = temp.map(item => {
    const currency = item.products[0].attributes.price.currency;
    const total = item.products.reduce(
      (prev, curr) => curr.attributes.price.amount * curr.quantity + prev,
      0
    );

    const totalCount = item.products.reduce((prev, curr) => parseInt(curr.quantity) + prev, 0);

    return {
      ...item,
      subtotal: total,
      currency,
      totalQuantity: totalCount,
    };
  });

  return tempWithPrice;
};

const getCartForProfile = newShoppingCart => {
  const groupedCart = lodash.groupBy(newShoppingCart.products, product => product.sellerId);

  let cart = Object.keys(groupedCart).map(key => {
    return {
      sellerId: key,
      products: groupedCart[key].map(product => {
        return { id: getId(product), quantity: product.quantity };
      }),
    };
  });

  return cart;
};

// ================ Thunks ================== //

export const getShoppingCart = user => {
  let shoppingCart = {};
  if (user) {
    const productsForCart = [];
    const userCart = user.attributes.profile.privateData.shoppingCart || [];
    userCart.forEach(cart => {
      const productsWithSellerId = cart.products.map(product => {
        return { id: product.id, quantity: product.quantity, sellerId: cart.sellerId };
      });
      productsForCart.push(...productsWithSellerId);
    });
    shoppingCart.products = productsForCart;
  } else {
    if (typeof localStorage !== 'undefined') {
      shoppingCart = JSON.parse(localStorage.getItem('shoppingCart'));
    }
  }
  return shoppingCart;
};

export const getItems = user => (dispatch, getState, sdk) => {
  dispatch(loadingData());
  const shoppingCart = getShoppingCart(user);

  const listingIds = shoppingCart?.products?.map(item => getId(item));

  if (shoppingCart?.expiryDate && moment(shoppingCart.expiryDate).isBefore(Date.now())) {
    localStorage.removeItem('shoppingCart');
    dispatch(oldCart());
  } else {
    const { variantPrefix = 'listing-card' } = config.listing;
    const params = (ids, key) => ({
      [key]: ids,
      include: ['author', 'images', 'currentStock'],
      'fields.image': [
        // Scaled variants for large images
        'variants.scaled-small',
        'variants.scaled-medium',
        'variants.scaled-large',
        'variants.scaled-xlarge',

        // Cropped variants for listing thumbnail images
        `variants.${variantPrefix}`,
        `variants.${variantPrefix}-2x`,
        `variants.${variantPrefix}-4x`,
        `variants.${variantPrefix}-6x`,

        // Social media
        'variants.facebook',
        'variants.twitter',

        // Avatars
        'variants.square-small',
        'variants.square-small2x',
      ],
    });

    const getParentImages = async (ids, sdk) => {
      const data = await sdk.listings.query(params(ids, 'ids'));
      let imagesArray = await data.data.included?.filter(extra => extra.type === 'image');
      return imagesArray ? imagesArray : [];
    };

    sdk.listings
      .query(params(listingIds, 'ids'))
      .then(async values => {
        const included = values.data.included;
        const itemsWithExtras = values.data.data.map(listing => {
          let parentImages = [];
          const rel = [
            listing.relationships.author.data.id.uuid,
            listing.relationships.currentStock.data.id.uuid,
            ...listing.relationships.images.data.map(image => image.id.uuid),
          ];
          const extras = included.filter(extra => rel.includes(extra.id.uuid));
          let extrasObj = { images: [] };
          extras.forEach(extra =>
            extra.type === 'image'
              ? (extrasObj.images = [...extrasObj.images, extra])
              : (extrasObj[extra.type] = extra)
          );
          const currentItem = shoppingCart.products.find(item => getId(item) === listing.id.uuid);

          if (listing.attributes?.publicData.isVariant) {
            const resp = getParentImages([listing.attributes.publicData.variantOf], sdk);
            const returnObj = resp.then(resp => {
              parentImages = resp;
              extrasObj.images = [...extrasObj.images, ...parentImages];
              return { ...listing, ...extrasObj, quantity: currentItem.quantity };
            });
            return returnObj;
          } else {
            return { ...listing, ...extrasObj, quantity: currentItem.quantity };
          }
        });

        const data = await Promise.all(itemsWithExtras);
        const bundledBySeller = bundleByAuthor(data);
        dispatch(getCartItems({ data: bundledBySeller, meta: values.data.meta }));
      })
      .catch(err => {
        dispatch(getCartItemsError(err));
        console.error(err);
        return err;
      });
  }
};

export const removeItem = (listingId, user) => (dispatch, getState, sdk) => {
  try {
    const cartItems = getState().ShoppingCart.cartItems.data.map(item => {
      const products = item.products.filter(product => product.id.uuid != listingId);
      if (products.length > 0) {
        const newTotalQuantity = products.reduce((prev, curr) => parseInt(curr.quantity) + prev, 0);

        return {
          ...item,
          totalQuantity: newTotalQuantity,
          subtotal: products.reduce(
            (prev, curr) => curr.attributes.price.amount * curr.quantity + prev,
            0
          ),
          products: item.products.filter(product => product.id.uuid !== listingId),
        };
      }
    });
    const shoppingCart = getShoppingCart(user);
    const newShoppingCart = {
      ...shoppingCart,
      products: shoppingCart.products.filter(item =>
        item.listingId ? item.listingId.uuid !== listingId : item.id !== listingId
      ),
    };

    if (newShoppingCart.products.length) {
      if (!user) {
        localStorage.setItem('shoppingCart', JSON.stringify(newShoppingCart));
        dispatch(
          deleteCartItem({ cartItems: cartItems.filter(item => item !== undefined), success: true })
        );
        dispatch(getCartSummary());
      } else {
        const cartForProfile = getCartForProfile(newShoppingCart);
        dispatch(updateProfile({ privateData: { shoppingCart: cartForProfile } }));
      }
      dispatch(
        deleteCartItem({ cartItems: cartItems.filter(item => item !== undefined), success: true })
      );
      dispatch(getCartSummary());
    } else {
      localStorage.removeItem('shoppingCart');
      dispatch(updateProfile({ privateData: { shoppingCart: [] } }));
      dispatch(deleteCartItem({ cartItems: [], success: true }));
      dispatch(getCartSummary());
    }
  } catch (err) {
    console.error(err);
    dispatch(deleteCartItemError(err));
  }
};

const getId = product => {
  return product.listingId ? product.listingId.uuid : product.id;
};

export const addItem = (listingId, values, listing, user) => (dispatch, getState, sdk) => {
  let shoppingCart = getShoppingCart(user);
  shoppingCart = !!shoppingCart ? shoppingCart : { products: [] };
  let shoppingCartProducts = shoppingCart.products;

  const isSelectedProductInCart = shoppingCartProducts.some(
    product => getId(product) === listingId.uuid
  );

  const newProducts = isSelectedProductInCart
    ? shoppingCartProducts.filter(product => getId(product) !== listingId.uuid)
    : shoppingCartProducts;

  const currentProduct = isSelectedProductInCart
    ? shoppingCartProducts.find(product => getId(product) === listingId.uuid)
    : {
        listingId,
        sellerId: listing.author.id.uuid,
        quantity: values?.quantity,
        updatedAt: Date.now(),
        price: listing.attributes.price,
        currentStock: listing.currentStock.attributes.quantity,
      };

  if (isSelectedProductInCart) {
    currentProduct['quantity'] = parseInt(currentProduct['quantity']) + parseInt(values.quantity);
  }
  const today = new Date(Date.now());
  const expiryDate = moment(today); // moment(...) can also be used to parse dates in string format
  const newExpiryDate = expiryDate.add(1, 'months');

  const updatedShoppingCart = {
    ...(shoppingCart || {}),
    expiryDate: newExpiryDate,
    products: [...newProducts, currentProduct],
  };

  const cartForProfile = getCartForProfile(updatedShoppingCart);

  if (!user) {
    localStorage.setItem('shoppingCart', JSON.stringify(updatedShoppingCart));
    dispatch(getCartSummary());
    dispatch(addToCart(listingId));
  } else {
    dispatch(getCartSummary());
    dispatch(updateProfile({ privateData: { shoppingCart: cartForProfile } }));
    dispatch(addToCart(listingId));
  }
};

export const changeAmount = (listingId, quantity, user) => (dispatch, getState, sdk) => {
  const shoppingCart = getShoppingCart(user);

  const cartItem = shoppingCart.products.find(item => getId(item) === listingId);
  cartItem.quantity = quantity;

  shoppingCart.products = shoppingCart.products.map(item => {
    if (getId(item) === listingId) {
      return cartItem;
    } else {
      return item;
    }
  });

  if (user) {
    let cartForProfile = getCartForProfile(shoppingCart);
    dispatch(updateProfile({ privateData: { shoppingCart: cartForProfile } }));
  } else {
    localStorage.setItem('shoppingCart', JSON.stringify(shoppingCart));
  }

  const cartItems = getState().ShoppingCart.cartItems;
  const newCart = cartItems.data.map(purchase => {
    let productWithNewAmount = purchase.products.find(item => item.id.uuid === listingId);

    if (!!productWithNewAmount) {
      productWithNewAmount.quantity = quantity;
    } else {
      productWithNewAmount = [];
    }

    let productsNew = new Array();
    purchase.products.forEach(product => {
      if (product.id.uuid !== listingId) {
        productsNew.push(product);
      } else {
        productsNew.push(productWithNewAmount);
      }
    });

    const newSubtotal = purchase.products.reduce((prev, curr) => {
      return curr.quantity * curr.attributes.price.amount + prev;
    }, 0);
    const newTotalQuantity = purchase.products.reduce(
      (prev, curr) => parseInt(curr.quantity) + prev,
      0
    );

    const newShippingCost = shippingFeeCalculation(
      purchase.products,
      newSubtotal,
      purchase.sellerId
    );

    return {
      ...purchase,
      totalQuantity: newTotalQuantity,
      shippingFee: newShippingCost,
      products: productsNew,
      subtotal: newSubtotal,
    };
  });

  dispatch(editAmount({ ...cartItems, data: newCart }));
  dispatch(getCartSummary());
};

export const getCartSummary = user => (dispatch, getState, sdk) => {
  const shoppingCart = getShoppingCart(user);
  if (shoppingCart) {
    let idsAndQuantity = {};
    let listingIds = [];
    shoppingCart.products.forEach(product => {
      if (product.listingId) {
        idsAndQuantity[product.listingId.uuid] = parseInt(product.quantity);
      } else {
        idsAndQuantity[product.id] = parseInt(product.quantity);
      }
      listingIds = [...listingIds, getId(product)];
    });
    const itemsCount = shoppingCart.products.reduce(
      (prev, curr) => parseInt(curr.quantity) + prev,
      0
    );

    const params = {
      ids: listingIds,
      include: ['author'],
    };
    sdk.listings.query(params).then(resp => {
      const totalValue = resp.data.data.reduce(
        (prev, curr) => curr.attributes.price.amount * idsAndQuantity[curr.id.uuid] + prev,
        0
      );

      const currency = resp.data.data[0] ? resp.data.data[0].attributes.price.currency : 'USD';

      dispatch(getCartAmountOfItems({ totalValue, itemsCount, currency }));
    });
  } else {
    dispatch(getCartAmountOfItems({ totalValue: 0, itemsCount: 0 }));
  }
};

export const shippingFeeCalculation = (
  products,
  sellerId,
  sellerShippingData,
  buyerCountry = 'US'
) => (dispatch, sdk, getState) => {
  const params = {
    orderData: {
      products: products,
      sellerShippingData: sellerShippingData,
      buyerAddress: { country: buyerCountry },
    },
  };

  transactionLineItems(params)
    .then(data => {
      if (data.data.error) {
        dispatch(calculateShipping({ sellerId, isForbidden: true }));
      } else {
        const dataShipping = data.data.find(item => item.code === 'line-item/shipping-fee');
        dispatch(calculateShipping({ shippingCost: dataShipping.lineTotal, sellerId }));
      }
    })
    .catch(err => {
      dispatch(calculateShipping({ sellerId, isForbidden: true }));
    });
};

export const mergeProfileCarts = user => (dispatch, getState, sdk) => {
  const localCart = JSON.parse(localStorage.getItem('shoppingCart'));
  const profileCart = getShoppingCart(user);
  const newCart = { products: profileCart?.products };
  if (localCart) {
    localCart.products.forEach(localItem => {
      const isLocalInProfile = profileCart.products.some(
        pItem => pItem.id == localItem.listingId.uuid
      );
      if (!isLocalInProfile) {
        newCart.products.push({
          id: localItem.listingId.uuid,
          quantity: localItem.quantity,
          sellerId: localItem.sellerId,
        });
      }
    });

    const cartForProfile = getCartForProfile(newCart);
    dispatch(updateProfile({ privateData: { shoppingCart: cartForProfile } })).then(() => {
      localStorage.removeItem('shoppingCart');
      return dispatch(emptyCart());
    });
  }
};

export const emptyLocalCart = () => (dispatch, getState, sdk) => {
  try {
    localStorage.removeItem('shoppingCart');
    return dispatch(emptyCart());
  } catch (err) {
    return dispatch(emptyCartError(err));
  }
};

export const clearFromCart = (listingIds, user) => (dispatch, getState, sdk) => {
  try {
    listingIds.forEach(item => {
      dispatch(removeItem(item, user));
    });
    dispatch(removePurcaseFromCartAfterPurchase());
  } catch (err) {
    dispatch(getCartSummary());
    dispatch(removePurcaseFromCartAfterPurchaseError(err));
  }
};

// ================ Selectors ================ //

// ================ Actions ================ //

export const getCartItems = data => ({
  type: GET_CART_ITEMS,
  payload: data,
});

export const emptyCart = () => ({
  type: EMPTY_CART,
});

export const getCartItemsError = error => ({
  type: GET_CART_ITEMS_ERROR,
  payload: error,
});

export const deleteCartItem = data => ({
  type: DELETE_CART_ITEM,
  payload: data,
});

export const deleteCartItemError = err => ({
  type: DELETE_CART_ITEM_ERROR,
  payload: err,
});

export const addToCart = listingId => ({
  type: ADD_TO_CART,
  payload: listingId,
});

export const oldCart = () => ({
  type: OLD_CART,
});

export const editAmount = cartItems => ({
  type: EDIT_AMOUNT,
  payload: cartItems,
});

export const getCartAmountOfItems = cartSummary => ({
  type: GET_CART_AMOUNT_AMOUNT_OF_ITEMS,
  payload: cartSummary,
});

export const loadingData = () => ({ type: LOADING_DATA });

export const calculateShipping = data => ({
  type: CALCULATE_SHIPPING,
  payload: data,
});

export const checkoutItems = storePurchase => ({
  type: CHECKOUT_ITEMS,
  payload: storePurchase,
});

export const getSeller = seller => ({ type: GET_SELLER, payload: seller });

export const removePurcaseFromCartAfterPurchase = () => ({
  type: REMOVE_PURCHASE_FROM_CART_AFTER_PURCHASE,
});
export const removePurcaseFromCartAfterPurchaseError = error => ({
  type: REMOVE_PURCHASE_FROM_CART_AFTER_PURCHASE_ERROR,
  payload: error,
});
export const emptyCartError = err => ({
  type: EMPTY_CART_ERROR,
  payload: error,
});
