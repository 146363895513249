/**
 * Export loadData calls from ducks modules of different containers
 */
import { loadData as CheckoutPageInitialValues } from './CheckoutPage/CheckoutPage.duck';
import { loadData as ContactDetailsPageLoader } from './ContactDetailsPage/ContactDetailsPage.duck';
import { loadData as ShopDetailsPageLoader } from './ShopDetailsPage/ShopDetailsPage.duck';
import { loadData as EditListingPageLoader } from './EditListingPage/EditListingPage.duck';
import { loadData as EmailVerificationPageLoader } from './EmailVerificationPage/EmailVerificationPage.duck';
import { loadData as InboxPageSellerLoader } from './InboxPageSeller/InboxPageSeller.duck';
import { loadData as SellerDashboardPageLoader } from './SellerDashboardPage/SellerDashboardPage.duck';
import { loadData as InboxPageBuyerLoader } from './InboxPageBuyer/InboxPageBuyer.duck';

import { loadData as ListingPageLoader } from './ListingPage/ListingPage.duck';
import { loadData as ManageListingsPageLoader } from './ManageListingsPage/ManageListingsPage.duck';

import { loadData as PaymentMethodsPageLoader } from './PaymentMethodsPage/PaymentMethodsPage.duck';
import { loadData as ProfilePageLoader } from './ProfilePage/ProfilePage.duck';
import { loadData as SearchPageLoader } from './SearchPage/SearchPage.duck';
import { loadData as StripePayoutPageLoader } from './StripePayoutPage/StripePayoutPage.duck';
import { loadData as PayPalMerchantPageLoader } from './PayPalMerchantPage/PayPalMerchantPage.duck';
import { loadData as LandingPageLoader } from './LandingPage/LandingPage.duck';
import { loadData as DigitalGoodsShowcasePageLoader } from './DigitalGoodsShowcasePage/DigitalGoodsShowcasePage.duck';
import {
  loadData as TransactionPageLoader,
  setInitialValues as TransactionPageInitialValues,
} from './TransactionPage/TransactionPage.duck';

const getPageDataLoadingAPI = () => {
  return {
    CheckoutPage: {
      loadData: CheckoutPageInitialValues,
    },
    LandingPage: {
      loadData: LandingPageLoader,
    },
    ContactDetailsPage: {
      loadData: ContactDetailsPageLoader,
    },
    ShopDetailsPage: {
      loadData: ShopDetailsPageLoader,
    },
    EditListingPage: {
      loadData: EditListingPageLoader,
    },
    EmailVerificationPage: {
      loadData: EmailVerificationPageLoader,
    },
    InboxPageSeller: {
      loadData: InboxPageSellerLoader,
    },
    SellerDashboardPage: {
      loadData: SellerDashboardPageLoader,
    },
    InboxPageBuyer: {
      loadData: InboxPageBuyerLoader,
    },
    ListingPage: {
      loadData: ListingPageLoader,
    },
    ManageListingsPage: {
      loadData: ManageListingsPageLoader,
    },
    PaymentMethodsPage: {
      loadData: PaymentMethodsPageLoader,
    },
    ProfilePage: {
      loadData: ProfilePageLoader,
    },
    SearchPage: {
      loadData: SearchPageLoader,
    },
 
    StripePayoutPage: {
      loadData: StripePayoutPageLoader,
    },
    PayPalMerchantPage: {
      loadData: PayPalMerchantPageLoader,
    },
    TransactionPage: {
      loadData: TransactionPageLoader,
      setInitialValues: TransactionPageInitialValues,
    },
    FullfillmentPage: {
      loadData: TransactionPageLoader,
      setInitialValues: TransactionPageInitialValues,
    },
    PayPalMerchantPage: {
      loadData: PayPalMerchantPageLoader,
    },
    DigitalGoodsShowcasePage: {
      loadData: DigitalGoodsShowcasePageLoader,
    },
  };
};

export default getPageDataLoadingAPI;
