import React, { useRef, useState, Fragment, useEffect } from 'react';
import { bool, func, number, string } from 'prop-types';
import { Form as FinalForm, Field, FormSpy } from 'react-final-form';
import { arrays_equal } from '../../../util/arrayHelpers';
import DownloadAppModal from '../../DownloadAppModal/DownloadAppModal';
import PadlockIcon from '../../PadlockIcon/PadlockIcon';

import config from '../../../config';
import { FormattedMessage, useIntl } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { numberAtLeast, required } from '../../../util/validators';

import { createResourceLocatorString } from '../../../util/routes';
import routeConfiguration from '../../../routing/routeConfiguration';
import { parse } from '../../../util/urlHelpers';
import { useHistory, useLocation } from 'react-router-dom';

import {
  Form,
  FieldSelect,
  FieldTextInput,
  InlineTextButton,
  PrimaryButton,
} from '../../../components';

import EstimatedCustomerBreakdownMaybe from '../EstimatedCustomerBreakdownMaybe';

import css from './ProductOrderForm.module.css';
import { motion, useAnimation } from 'framer-motion';
import NamedLink from '../../NamedLink/NamedLink';
import Button, { SecondaryButton } from '../../Button/Button';
import classNames from 'classnames';
import { getShoppingCart } from '../../../containers/ShoppingCart/ShoppingCart.duck';

const renderForm = formRenderProps => {
  const {
    // FormRenderProps from final-form
    handleSubmit,
    form: formApi,

    // Custom props passed to the form component
    intl,
    formId,
    currentStock,
    hasMultipleDeliveryMethods,
    listingId,
    currentUser,
    listing,
    onFetchTransactionLineItems,
    onContactUser,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    values,
    addToCart,
    variantSelected,
    slug,
    form,
    isToCart,
    isModal,
    isOwnListing,
    isExclusiveDisabled,
    productType,
  } = formRenderProps;

  const { publicData } = listing?.attributes;

  let shoppingCart = { products: [] };

  shoppingCart = getShoppingCart(currentUser);

  const listingFromCart = shoppingCart?.products
    ? shoppingCart.products.find(item =>
        item.listingId ? item.listingId.uuid === listingId.uuid : item.id === listingId.uuid
      )
    : null;

  const isOnShoppingCart = !!listingFromCart || false;

  const listingQuantityFromCart =
    isOnShoppingCart && listingFromCart ? parseInt(listingFromCart.quantity) : 0;

  const purchasedProducts = currentUser?.attributes?.profile?.privateData?.purchasedProducts || [];

  const isProductAlreadyPurchased = purchasedProducts.some(
    product => product.id === listing.id.uuid
  );

  const purchasedQuantity = isProductAlreadyPurchased
    ? parseInt(purchasedProducts.find(product => product.id === listing.id.uuid)?.quantity)
    : 0;

  const hasPurchaseQuantityCap =
    !!publicData?.purchaseQuantityCap && parseInt(publicData?.purchaseQuantityCap) > 0;

  const purchaseQuantityCap = hasPurchaseQuantityCap ? parseInt(publicData.purchaseQuantityCap) : 0;

  const stockLimitExceeded =
    typeof currentStock !== 0
      ? values.quantity
        ? listingQuantityFromCart + parseInt(values.quantity) > currentStock
        : listingQuantityFromCart >= currentStock
      : false;
  const isFree = listing.attributes?.price?.amount == 0;
  const isReward = publicData.exclusiveInfo?.isExclusive;

  const cartInfoControls = useAnimation();
  const visible = { y: 0, opacity: 1 };
  const hidden = { y: 50, opacity: 0 };
  const update = {
    scale: [null, 1.08, 1],
    transition: {
      duration: 0.25,
    },
  };

  const handleOnChange = formValues => {
    const { quantity: quantityRaw, deliveryMethod } = formValues.values;
    const quantity = Number.parseInt(quantityRaw, 10);
    const isBrowser = typeof window !== 'undefined';

    if (isBrowser && quantity && deliveryMethod && !fetchLineItemsInProgress) {
      onFetchTransactionLineItems({
        orderData: {
          quantity,
          sellerShippingData: {
            ...listing.author.attributes.profile.publicData,
          },
          buyerAddress: {},
          products: [{ ...listing, quantity }],
        },
      });
    }
  };

  const initialValue = () => {
    let separated = listing.attributes.publicData?.variantType
      ? listing.attributes.publicData.variantType.attr.map(x => {
          const splitted = x.split('_');
          return { [splitted[0]]: splitted[1] };
        })
      : [];

    return {
      ...separated[0],
      ...separated[1],
      ...separated[2],
    };
  };
  const [atrValues, setAtrValues] = useState(initialValue());

  const history = useHistory();
  const location = useLocation();

  const onAttributeChange = (event, fieldName) => {
    const copyOfValues = { ...atrValues };
    copyOfValues[fieldName] = event.target.value;
    const arrayOfChangedAttributes = Object.entries(copyOfValues).map(item => {
      return item[0] + '_' + item[1];
    });
    const variantToSwitchTo = listing.attributes.publicData.variants.find(
      x => arrays_equal(x.attr, arrayOfChangedAttributes) && !x.isVariantDeleted
    );

    if (variantToSwitchTo) {
      const { search } = location;
      const adding = parse(search);

      history.push(
        createResourceLocatorString(
          'ListingPage',
          routeConfiguration(),
          { id: variantToSwitchTo.id, slug: slug },
          { ...adding }
        )
      );
    }
    setAtrValues(copyOfValues);
  };

  // In case quantity and deliveryMethod are missing focus on that select-input.
  // Otherwise continue with the default handleSubmit function.
  const handleFormSubmit = e => {
   
    const { quantity, deliveryMethod } = values || {};

    const fieldsNames = listingAttributes
      ? listingAttributes.map(x => {
          return x.id ? x.type + '-' + x.id : x.type;
        })
      : [];

    fieldsNames.forEach(element => {
      if (!values[element]) {
        e.preventDefault();
        formApi.blur(element);
        formApi.focus(element);
      }
    });
    if (!quantity || quantity < 1) {
      e?.preventDefault();
      // Blur event will show validator message
      formApi.blur('quantity');
      formApi.focus('quantity');
    } else if (!deliveryMethod) {
      e.preventDefault();
      // Blur event will show validator message
      formApi.blur('deliveryMethod');
      formApi.focus('deliveryMethod');
    } else if (e.target.name === 'buyNow' || e.target.parentNode.parentNode.name == 'buyNow') {
      const state = formApi.getState();
      if (!state.invalid) {
        handleSubmit(e);
      }
    } else {
      e.preventDefault();
      const state = formApi.getState();
      if (!state.invalid) {
        addToCart(e, values);

        if (!isOnShoppingCart) {
          cartInfoControls.start(visible);
        } else {
          cartInfoControls.start(update);
        }
      }
    }
  };

  const breakdownData = {};
  const showBreakdown =
    breakdownData && lineItems && !fetchLineItemsInProgress && !fetchLineItemsError;
  const breakdown = showBreakdown ? (
    <div className={css.breakdownWrapper}>
      <EstimatedCustomerBreakdownMaybe
        unitType={config.lineItemUnitType}
        breakdownData={breakdownData}
        lineItems={lineItems}
      />
    </div>
  ) : null;

  const showContactUser = typeof onContactUser === 'function';

  const onClickContactUser = e => {
    e.preventDefault();
    onContactUser();
  };

  const contactSellerLink = (
    <InlineTextButton onClick={onClickContactUser}>
      <FormattedMessage id="ProductOrderForm.finePrintNoStockLinkText" />
    </InlineTextButton>
  );
  const quantityRequiredMsg = intl.formatMessage({
    id: 'ProductOrderForm.quantityRequired',
  });

  const hasStock = currentStock && currentStock > 0;

  const hasNoStockLeft = typeof currentStock != null && currentStock === 0;
  const hasOneItemLeft = typeof currentStock != null && currentStock === 1;

  const quantityCartAndPurchased =
    currentStock >= purchaseQuantityCap
      ? purchaseQuantityCap - listingQuantityFromCart - purchasedQuantity
      : currentStock - listingQuantityFromCart - purchasedQuantity;
  const quantityCartOnly = currentStock - listingQuantityFromCart;
  const availableQuantity = hasPurchaseQuantityCap ? quantityCartAndPurchased : quantityCartOnly;
  const quantities =
    availableQuantity > 0 && hasStock ? [...Array(availableQuantity).keys()].map(i => i + 1) : [];

  const quantityCapInfo = (
    <div
      className={css.quantityCapInfo}
      style={{
        color:
          quantityCartAndPurchased > 0
            ? 'var(--fave-color-primary-s500)'
            : 'var(--fave-color-error-s500)',
      }}
    >
      {quantityCartAndPurchased > 0
        ? intl.formatMessage(
            { id: 'ProductOrderForm.purchaseQuantityCapInfo' },
            {
              quantityCartAndPurchased,
            }
          )
        : intl.formatMessage({ id: 'ProductOrderForm.purchaseQuantityCapReached' })}
    </div>
  );

  const cartContentInfo = (
    <motion.div
      animate={cartInfoControls}
      className={css.cartInfoWrapper}
      initial={hidden}
      style={{
        pointerEvents: isOnShoppingCart ? 'initial' : 'none',
      }}
    >
      <NamedLink name="ShoppingCart" style={{ textDecoration: 'none' }}>
        <div className={css.cartInfo}>
          {intl.formatMessage(
            { id: 'ProductOrderForm.quantityInCart' },
            {
              listingQuantityFromCart,
              products: listingQuantityFromCart > 1 ? 'products' : 'product',
            }
          )}
        </div>
      </NamedLink>
    </motion.div>
  );
  const submitInProgress = fetchLineItemsInProgress;
  const submitDisabled =
    !hasStock || stockLimitExceeded || (hasPurchaseQuantityCap && quantityCartAndPurchased <= 0);
  const isVariantParent =
    listing.attributes?.publicData?.attributes?.length > 0 &&
    !listing.attributes?.publicData?.isVariant;
  const hasVariants =
    listing.attributes?.publicData?.attributes &&
    listing.attributes?.publicData?.attributes?.length > 0;
  const listingAttributes = listing.attributes?.publicData?.attributes || [];
  const shippingCovered = listing.attributes?.publicData?.shippingCovered;
  const shippingLabel = shippingCovered
    ? 'physicalCovered'
    : productType
    ? productType
    : 'physical';

  useEffect(() => {
    if (isOnShoppingCart) cartInfoControls.start(visible);
  }, [isOnShoppingCart]);

  useEffect(() => {
    if (stockLimitExceeded || quantityCartAndPurchased <= 1) form.change('quantity', '1');
  }, [stockLimitExceeded, quantityCartAndPurchased]);

  const [downloadAppModal, setDownloadAppModal] = useState(false);
  const handleModalDownloadApp = () => {
    setDownloadAppModal(prev => !prev);
  };

  return (
    <form onSubmit={() => {}}>
      <FormSpy subscription={{ values: true }} onChange={handleOnChange} />
      <DownloadAppModal
        forItem={true}
        isOpen={downloadAppModal}
        onClose={() => handleModalDownloadApp()}
      />

      {listingAttributes
        ? listingAttributes.map(x => {
            const idString = x.id ? x.type + '-' + x.id : x.type;
            const optionsToShow = x.values.map(x => {
              return {
                key: x.value,
                label: x.label,
                value: x.value,
              };
            });
            return (
              <Fragment key={x.type + x.id}>
                <FieldSelect
                  name={idString}
                  placeholder="Pick option"
                  initialValue={atrValues[idString]}
                  label={x.title}
                  id={idString}
                  validate={required('Please select')}
                  className={css.quantityField}
                  onChange={change => {
                    formApi.change(idString, change.target.value);
                    onAttributeChange(change, idString);
                  }}
                >
                  <option disabled value="">
                    {x.title}
                  </option>
                  {optionsToShow.map(c => (
                    <option key={c.value} value={c.value}>
                      {c.label}
                    </option>
                  ))}
                </FieldSelect>
              </Fragment>
            );
          })
        : null}

      {hasPurchaseQuantityCap && (!isVariantParent || (isVariantParent && !hasVariants))
        ? quantityCapInfo
        : null}

      {hasNoStockLeft || isVariantParent ? null : hasOneItemLeft ? (
        <FieldTextInput
          id={`${formId}.quantity`}
          className={css.quantityField}
          name="quantity"
          type="hidden"
          validate={numberAtLeast(quantityRequiredMsg, 1)}
        />
      ) : (
        <FieldSelect
          id={`${formId}.quantity`}
          className={css.quantityField}
          name="quantity"
          disabled={!hasStock}
          label={intl.formatMessage({
            id: 'ProductOrderForm.quantityLabel',
          })}
          validate={numberAtLeast(quantityRequiredMsg, 1)}
        >
          <option disabled value="">
            {!stockLimitExceeded
              ? intl.formatMessage({
                  id: 'ProductOrderForm.selectQuantityOption',
                })
              : intl.formatMessage({
                  id: 'ProductOrderForm.stockLimitExceededOption',
                })}
          </option>
          {quantities.slice(0, 100).map(quantity => (
            <option key={quantity} value={quantity}>
              {intl.formatMessage({ id: 'ProductOrderForm.quantityOption' }, { quantity })}
            </option>
          ))}
        </FieldSelect>
      )}

      {hasNoStockLeft ? null : hasMultipleDeliveryMethods ? (
        <FieldSelect
          id={`${formId}.deliveryMethod`}
          className={css.deliveryField}
          name="deliveryMethod"
          disabled={!hasStock}
          label={intl.formatMessage({
            id: 'ProductOrderForm.deliveryMethodLabel',
          })}
          validate={required(
            intl.formatMessage({
              id: 'ProductOrderForm.deliveryMethodRequired',
            })
          )}
        >
          <option disabled value="">
            {intl.formatMessage({
              id: 'ProductOrderForm.selectDeliveryMethodOption',
            })}
          </option>
          <option value={'shipping'}>
            {intl.formatMessage({
              id: 'ProductOrderForm.shippingOption',
            })}
          </option>
        </FieldSelect>
      ) : (
        !listing.attributes.publicData.isReferral && (
          <div className={css.deliveryField}>
            <h5>Shipping</h5>
            <p>
              {intl.formatMessage({
                id: `ProductOrderForm.${shippingLabel}`,
              })}
            </p>
            <FieldTextInput
              id={`${formId}.deliveryMethod`}
              className={css.deliveryField}
              name="deliveryMethod"
              type="hidden"
            />
          </div>
        )
      )}
      {!listing.attributes.publicData.isReferral && breakdown}
      {cartContentInfo}
      <div
        className={classNames(css.submitButton, {
          [css.cursorNotAllowed]: submitDisabled,
        })}
      >
        {listing.attributes.publicData.isReferral && (
          <PrimaryButton
            type="button"
            className={classNames(css.orderButton, {
              [css.orderButtonDisabled]: submitDisabled || isExclusiveDisabled,
            })}
            onClick={e => window.open(listing.attributes.publicData.referralUrl)}
            inProgress={submitInProgress}
            name="refer"
            disabled={submitDisabled}
          >
            Visit page
          </PrimaryButton>
        )}
        {!listing.attributes.publicData.isReferral && (!isModal || !isToCart) && (
          <PrimaryButton
            type="button"
            className={classNames(css.orderButton, {
              [css.orderButtonDisabled]: submitDisabled || isExclusiveDisabled,
            })}
            onClick={e => (!isExclusiveDisabled ? handleFormSubmit(e) : handleModalDownloadApp())}
            inProgress={submitInProgress}
            name="buyNow"
            disabled={submitDisabled}
          >
            {hasStock ? (
              <div className={css.buttonContent}>
                {isExclusiveDisabled && <PadlockIcon />}
                <span className={css.marginLeft}>
                  {isFree && isReward ? 'Redeem now' : isFree ? 'Get Now' : 'Buy Now'}
                </span>
              </div>
            ) : (
              <FormattedMessage id="ProductOrderForm.ctaButtonNoStock" />
            )}
          </PrimaryButton>
        )}
        {((!isModal && !listing.attributes.publicData.isReferral) || isToCart) &&
          (isFree && isReward ? null : (
            <SecondaryButton
              type="button"
              onClick={e => (!isExclusiveDisabled ? handleFormSubmit(e) : handleModalDownloadApp())}
              inProgress={submitInProgress}
              name="addToCart"
              className={classNames({
                [css.orderButtonDisabled]: submitDisabled || isExclusiveDisabled,
              })}
              disabled={submitDisabled}
            >
              {hasStock ? (
                <div className={css.buttonContent}>
                  {isExclusiveDisabled && <PadlockIcon />}
                  <span className={css.marginLeft}>
                    <FormattedMessage id="OrderPanel.addToCartButtonMessage" />
                  </span>
                </div>
              ) : (
                <FormattedMessage id="ProductOrderForm.ctaButtonNoStock" />
              )}
            </SecondaryButton>
          ))}
      </div>
      {!listing.attributes.publicData.isReferral && (
        <p className={css.finePrint}>
          {hasStock && variantSelected ? (
            <>{!isExclusiveDisabled && <FormattedMessage id="ProductOrderForm.finePrint" />}</>
          ) : !variantSelected ? (
            <FormattedMessage id="ProductOrderForm.finePrintNoVariantSelected"></FormattedMessage>
          ) : showContactUser ? (
            <FormattedMessage
              id="ProductOrderForm.finePrintNoStock"
              values={{ contactSellerLink }}
            />
          ) : null}
        </p>
      )}
    </form>
  );
};

const ProductOrderForm = props => {
  const intl = useIntl();
  const { price, currentStock, pickupEnabled, shippingEnabled } = props;

  // Should not happen for listings that go through EditListingWizard.
  // However, this might happen for imported listings.
  if (!pickupEnabled && !shippingEnabled) {
    return (
      <p className={css.error}>
        <FormattedMessage id="ProductOrderForm.noDeliveryMethodSet" />
      </p>
    );
  }

  if (!price) {
    return (
      <p className={css.error}>
        <FormattedMessage id="ProductOrderForm.listingPriceMissing" />
      </p>
    );
  }
  if (price.currency !== config.currency) {
    return (
      <p className={css.error}>
        <FormattedMessage id="ProductOrderForm.listingCurrencyInvalid" />
      </p>
    );
  }
  const hasOneItemLeft = currentStock && currentStock === 1;
  const quantityMaybe = hasOneItemLeft ? { quantity: '1' } : {};
  const singleDeliveryMethodAvailableMaybe =
    shippingEnabled && !pickupEnabled
      ? { deliveryMethod: 'shipping' }
      : !shippingEnabled && pickupEnabled
      ? { deliveryMethod: 'pickup' }
      : {};
  const hasMultipleDeliveryMethods = pickupEnabled && shippingEnabled;
  const initialValues = { ...quantityMaybe, ...singleDeliveryMethodAvailableMaybe };

  return (
    <FinalForm
      initialValues={initialValues}
      hasMultipleDeliveryMethods={hasMultipleDeliveryMethods}
      {...props}
      intl={intl}
      render={renderForm}
    />
  );
};

ProductOrderForm.defaultProps = {
  rootClassName: null,
  className: null,
  price: null,
  currentStock: null,
  listingId: null,
  listingInCart: false,
  isOwnListing: false,
  lineItems: null,
  fetchLineItemsError: null,
};

ProductOrderForm.propTypes = {
  rootClassName: string,
  className: string,

  // form
  formId: string.isRequired,
  onSubmit: func.isRequired,

  // listing
  listingId: propTypes.uuid,
  price: propTypes.money,
  currentStock: number,
  isOwnListing: bool,
  listingInCart: bool,

  // line items
  lineItems: propTypes.lineItems,
  onFetchTransactionLineItems: func.isRequired,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // other
  onContactUser: func,
};

export default ProductOrderForm;
